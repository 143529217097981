import merge from "lodash.merge";
import { DefaultTheme } from "styled-components";
import defaultTheme, { colors } from "./defaultTheme";

const loadingTheme: DefaultTheme = merge({}, defaultTheme, {
  colors: {
    text: colors.white,
    background: colors.loadingGray,
  },
  fonts: {
    baseSizePercentage: 150,
  },
});

export default loadingTheme;
