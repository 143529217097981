import { DefaultTheme } from "styled-components";

const colors = {
  black: "#121212",
  darkGray: "#23242A",
  middleGray: "#57585D",
  lightGray: "#A6A6A8",
  white: "#FFFFFF",
  offWhite: "#EFEADC",
  darkSlateBlue: "#1D2032",
  lightSlateBlue: "#434972",
  loadingGray: "#313131",
  lighterPurple: "#434971",
  lightPurple: "#696F96",
  purple: "#955FD5",
  red: "#FF5863",
  gold: "#C87A15",
  orange: "#FF8E2E",
  yellow: "#FFB83E",
  brightYellow: "#FFC614",
  darkGreen: "#2AAD52",
  lightBlue: "#79F2FF",
  brightBlue: "#1eb1fc",
  darkBlue: "#2A99AD",
  lightGreen: "#B7FF39",
  pink: "#FF53F8",
};

const defaultTheme: DefaultTheme = {
  tileSize: 36,
  colors: {
    text: colors.offWhite,
    textSecondary: colors.lightGray,
    background: colors.black,
    positive: colors.lightGreen,
    clue: colors.yellow,
    coin: {
      dark: colors.gold,
      light: colors.yellow,
    },
    perfectScoreHeader: colors.brightYellow,
    modal: {
      background: colors.offWhite,
      text: colors.darkSlateBlue,
      // background: "#FFFCF2",
      // text: "#041624",
    },
    reviewBar: {
      background: colors.offWhite,
      text: colors.darkSlateBlue,
      // background: "#FFFCF2",
      // text: "#041624",
    },
    tile: {
      hidden: colors.lightSlateBlue,
      hiddenHovered: colors.lightPurple,
      revealed: colors.darkGray,
      revealedHovered: colors.middleGray,
    },
    mainMenu: {
      playButtonText: colors.white,
      playButtonBg: colors.red,
      playButtonDisabled: colors.darkGray,
      helpButtonText: colors.black,
      helpButtonBg: colors.white,
    },
    numbers: {
      1: colors.lightBlue,
      2: colors.darkGreen,
      3: colors.orange,
      4: colors.purple,
      5: colors.red,
      6: colors.darkBlue,
      7: colors.lightGreen,
      8: colors.pink,
    },
    settings: {
      background: colors.darkSlateBlue,
      toggleBackground: colors.lightSlateBlue,
      toggleText: colors.black,
      toggleElement: colors.white,
      text: colors.offWhite,
    },
    shareModal: {
      letsGoButtonBg: colors.brightBlue,
    },
    onboardingModal: {
      background: colors.lighterPurple,
      text: colors.white,
      textStrong: colors.yellow,
      primaryButton: colors.yellow,
      radioActive: colors.yellow,
      radioNotActive: colors.black,
    },
  },
  animations: {
    tileReveal: "tada",
  },
  fonts: {
    baseSizePercentage: 100,
    baseStack: "'Neuzeit Grotesk', 'Familjen Grotesk', Helvetica, sans-serif",
    boldStack: "NeuzeitGro-Bol, 'Familjen Grotesk', Helvetica, sans-serif",
    serifStack: "Montserrat, Aleo, serif",
    monoStack: "'Courier New', Courier, monospace",
    settings: {
      baseSize: 1.5,
    },
  },
};

export { colors };
export default defaultTheme;
